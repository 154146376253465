import { Box } from "@mui/material";
const PausedOverlay = ({ text,poster }) => (
  <Box>
    <img src={poster} alt="no img to show" className={"thumbnail-image"} />
    <Box className="img-with-text">
      <p className="text-inside-img">{text}</p>
    </Box>
  </Box>
);

export default PausedOverlay;
