import { Box } from "@mui/material";
import "./style.css";
import React from "react";

export const MiddleText = () => {
  return (
    <Box className="middle-text-box">
      <p className="title">MEDIA FOR ALL.</p>
      <p className="sub-title">- STORY TELLING -</p>
    </Box>
  );
};
