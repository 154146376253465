import * as React from "react";

import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const FAQList = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [secandItem, setSecandItemOpen] = React.useState(false);

  const handleClickSecandItem = () => {
    setSecandItemOpen(!secandItem);
  };

  const [therdItem, setTherdItemOpen] = React.useState(false);

  const handleClickTherdItem = () => {
    setTherdItemOpen(!therdItem);
  };

  const [fourdItem, setFourdItemOpen] = React.useState(false);

  const handleClickFourdItem = () => {
    setFourdItemOpen(!fourdItem);
  };
  const [lastItem, setlastItemOpen] = React.useState(false);

  const handleClickLastItem = () => {
    setlastItemOpen(!lastItem);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={"center"}
        fontSize={64}
        color={"white"}
        marginTop={10}
        
      >
        FAQ'S
      </Box>
      <Box display="flex" justifyContent={"center"} >
        <List
          sx={{
            width: "100%",
            height:600,
            maxWidth: 950,
            marginLeft: "100px",
            marginInlineEnd: 20,
            marginBottom:10,
            "& > :not(style)": {
              m: 1,
              width: "100%",
            },
            color: "white",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleClick}>
            <ListItemText primary="Why Lab Studio?" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Lab Studio works with a diverse range of clients, including small and medium-sized businesses, startups, and established brands across various industries. We have a proven track record of success in working with clients in sectors such as technology, e-commerce, hospitality, fashion, and entertainment. Our team's expertise allows us to tailor our services to meet the unique needs and goals of each client, regardless of their size or industry." />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickSecandItem}>
            <ListItemText primary=" What clients does Lab Studios work with?" />
            {secandItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={secandItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Lab Studio is a cutting-edge media agency that specialises in creating innovative and impactful content solutions for businesses and brands. We offer a range of services, including branding, content creation, Studio rental, video editing, videography, to help our clients achieve their content creation objectives and stand out in a crowded media landscape." />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickTherdItem}>
            <ListItemText primary=" How does Lab Studio stay ahead of the ever-changing media landscape?" />
            {therdItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={therdItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Lab Studio is committed to staying at the forefront of the media industry and keeping up with the latest trends and technologies. Our team constantly monitors the media landscape, conducts research, and participates in industry events to stay informed about emerging opportunities and best" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClickFourdItem}>
            <ListItemText primary=" What services does Lab Studio offer?" />
            {fourdItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={fourdItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Lab Studio offers a comprehensive range of media services, including branding, content creation, video production, studio rental, photography, video editing and more. We provide end-to-end solutions that cover every aspect of the media landscape, from concept development to execution. Our goal is to provide our clients with a seamless and integrated media experience that drives results and helps them achieve their marketing objectives." />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClickLastItem}>
            <ListItemText primary="How does Lab Studio approach branding and content creation?" />
            {lastItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={lastItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText
                  primary="Lab Studio believes that branding and content creation are two sides of the same coin. Our approach to branding starts with a deep understanding of our clients' values, vision, and target audience. We then use this insight to create a compelling brand identity that resonates with consumers and sets our clients apart from their competitors. 
Our content creation process is similarly strategic, with a focus on creating content that is not only visually appealing but also engaging, relevant, and shareable. We leverage storytelling, visual design, and multimedia elements to create content that captivates audiences and drives brand affinity."
                />

              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
    </>
  );
};
