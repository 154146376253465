import "./style.css";
import { Box } from "@mui/material";
import React from "react";
import { MainBanner, MarqueeComponent, ContentSection } from "../components";

export const LandingPage = () => {
  return (
    <Box className="main-body">
      <Box
        sx={{
          marginBottom: "297px",
        }}
      >
        <MainBanner />
      </Box>
      <MarqueeComponent />
      <ContentSection />
    </Box>
  );
};
