import { Box } from "@mui/material";
import "./style.css";
import { Header, MiddleText } from "..";
import React from "react";
import videoPlayer from "assets/vidoes/back.mp4";
export const MainBanner = () => {
  return (
    <Box className="main-mid-banner-box">
      <Header />
      <div class="video-container">
        <video  autoPlay loop muted >
          <source src={videoPlayer} type="video/mp4" />
        </video>
        <div class="caption">
          <MiddleText />
        </div>
      </div>
    </Box>
  );
};
