import { Box } from "@mui/material";
import "./style.css";
import LabStududio from "assets/imgs/LabStududio.png";
import LabStududioo from "assets/imgs/adore.png";

export const LabStudioComponent = () => {
  return (
    <>
      <Box display={"flex"} justifyContent={"start"}>
        <div>
          <div className="title">Lab studio. </div>
          <span className="noun">noun</span>
        </div>
      </Box>
      <Box>
        <div className="sub-title-lab">
          a media agency who helps in creating unique and stylised films
          spanning many genres.
        </div>
      </Box>
      <Box display={"flex"} justifyContent={"start"}>
        <div className={"img"}>
          <img
            height={767}
            width={616}
            src={LabStududio}
            alt="no img to show"
          />
        </div>

        <Box color={"white"}>
          <div className={"small-img"}>
            <img
              height={558}
              width={400}
              src={LabStududioo}
              alt="no img to show"
            />
          </div>
          <div className={"small-data"}>
            Lab studio is Australia's leading
            <br />
            modern media production studio.
            <br /> Its our mission to create visual
            <br /> stories that are guaranteed to
            <br /> gain the attention of the masses.
          </div>
        </Box>
      </Box>
    </>
  );
};
