import "./style.css";
import { Box, Typography } from "@mui/material";
import React from "react";
import LabStudio from "../../../assets/icons/lab.svg";

export const Header = () => {
  return (
    <Box className="main-header-box">
      <Box className="left-side">
        <img src={LabStudio} alt="SSS" />
      </Box>
      <Box className="right-side">
        <Typography className="header-item">Home</Typography>
        <Typography className="header-item">Work</Typography>
        <Typography className="header-item">About</Typography>
        <Typography className="header-item"> Contact</Typography>
      </Box>
    </Box>
  );
};
