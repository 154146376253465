import React, { Fragment } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import HoverVideoPlayer from "react-hover-video-player";
import PausedOverlay from "./PausedOverlay";
import video from "assets/vidoes/back.mp4";
import video2 from "assets/vidoes/sl1.mp4";
import video3 from "assets/vidoes/sl3.mp4";

import allCarpet from "assets/icons/all-carpet.svg";
import thorair from "assets/icons/thorair.svg";
import allImports from "assets/icons/all-imports.svg";
import labadi from "assets/icons/labadi.svg";
import restoration from "assets/icons/restoration.svg";
import poster from "assets/imgs/img.png";
import tell from "assets/imgs/Tell.png";
import Stories from "assets/imgs/Stories.png";


export const MiddleBannerMainBanner = () => {
  return (
    <Fragment>
      <Box className="text-section">
        <Box>
          <p className="middle-banner-title">
            Convey your story with Lab Studios
          </p>
          <p className="middle-banner-subtitle">
            We will help bring the image in your mind too life.
          </p>
        </Box>

        <Box className="grid-container">
          <HoverVideoPlayer
            videoSrc={video}
            pausedOverlay={<PausedOverlay text={"WE"} poster={poster} />}
            className="grid-item"
            videoClassName="video-play"
            hoverOverlay={
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  marginBottom: 18,
                  marginInline: 2,
                  textAlign: "start",
                }}
              >
                <Typography color={"white"} fontSize={"20px"}>
                  We also have videographers ready, Wherever, whenever, we come
                  to you.{" "}
                </Typography>
              </Box>
            }
          />
          <HoverVideoPlayer
            videoSrc={video2}
            pausedOverlay={<PausedOverlay text={"Tell"} poster={tell} />}
            className="grid-item"
            videoClassName="video-play"
            hoverOverlay={
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  marginBottom: 18,
                  marginInline: 2,
                  textAlign: "start",
                }}
              >
                <Typography color={"white"} fontSize={"20px"}>
                  We also have videographers ready, Wherever, whenever, we come
                  to you.{" "}
                </Typography>
              </Box>
            }
            style={{
              marginTop: "58px",
            }}
          />
          <Box
            style={{
              marginLeft: "250px",
              paddingTop: "52px",
            }}
          >
            <HoverVideoPlayer
              videoSrc={video3}
              pausedOverlay={<PausedOverlay text={"Stories"} poster={Stories} />}
              className="grid-item"
              videoClassName="video-play"
              hoverOverlay={
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    left: 10,
                    marginBottom: 18,
                    marginInline: 2,
                    textAlign: "start",
                  }}
                >
                  <Typography color={"white"} fontSize={"20px"}>
                    For the full Lab Studio experience take on one of our video
                    editing specialists to bring together your vision.
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>
      </Box>
      <Box paddingTop="190px">
        <p className="proclaimed">Proclaimed clients and partners.</p>
      </Box>
      <Box className="trade-marks" paddingTop="42px">
        <img
          src={allCarpet}
          width={102}
          height={84}
          alt="all carpet"
          className="trade-marks-logo"
        />
        <img
          src={thorair}
          width={144}
          height={61}
          alt="thorair"
          className="trade-marks-logo"
        />
        <img
          src={allImports}
          width={94}
          height={104}
          alt="allImports"
          className="trade-marks-logo"
        />
        <img
          src={labadi}
          width={182}
          height={120}
          alt="labadi"
          className="trade-marks-logo"
        />
        <img
          src={restoration}
          width={197}
          height={130}
          alt="restoration"
          className="trade-marks-logo"
        />
      </Box>
    </Fragment>
  );
};
