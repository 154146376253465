import { Box } from "@mui/material";
import React from "react";
import { MarqueeComponent, MiddleBannerMainBanner } from "..";
import "./style.css";
import { LabStudioComponent } from "../LabStudio/LabStudio";
import { FAQList } from "../FAQList/FAQList";
import { Footer } from "../Footer/Footer";
export const ContentSection = () => {
  return (
    <>
      <Box className="section-banner">
        <MiddleBannerMainBanner />
      </Box>
      <LabStudioComponent />
      <FAQList />
      <MarqueeComponent />
      <Footer />
    </>
  );
};
