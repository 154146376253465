import { Box, Grid } from "@mui/material";
import facebook from "assets/icons/facebook.svg";
import youtoub from "assets/icons/youtoub.svg";
import tweeter from "assets/icons/tweeter.svg";
import ticktok from "assets/icons/ticktok.svg";
import insta from "assets/icons/insta.svg";
import LabStudio from "../../../assets/icons/lab.svg";
import "./style.css";

export const Footer = () => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        color={"#7B7B7B"}
        fontSize={"26px"}
        fontFamily={"fahkwang"}
        marginTop={2}
      >
        INFO@LABSTUDIOS.COM.AU
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        fontSize={"96px"}
        color={"white"}
        fontFamily={"fahkwang"}
        marginTop={1}
      >
        GET IN TOUCH NOW.
      </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={"138px"}
          paddingTop="60px"
          paddingBottom={'60px'}
        >
          <img src={facebook} alt="all carpet" />
          <img src={youtoub} alt="thorair" />
          <img src={tweeter} alt="allImports" />
          <img src={ticktok} alt="labadi" />
          <img src={insta} alt="restoration" />
        </Box>
        <Box display={"flex"}
          justifyContent={"center"}
          gap={"138px"}
          paddingTop="60px"
          color={'white'}
          fontSize={'23px'}
          paddingBottom={'60px'}
          className="footer"

          >
        <img width={128} height={40} src={LabStudio} alt="LabStudio img not found" />
        <div>Contact us </div>
        <div>Services</div>
        <div>Our Work </div>
        <div>Website designed by Lab Studio </div>
        
        </Box>
        
    </>
  );
};
