import React from "react";
import "./style.css";
import Square from "../../../../src/assets/icons/square.svg";
import Marquee from "react-fast-marquee";
import { Box } from "@mui/material";
export const MarqueeComponent = () => {
  return (
    <Marquee speed={150}>
      <Box className="marquee-box">
        <p className="marquee-text">PROFESSIONAL STUDIO</p>
        <img src={Square} alt="square" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">PHOTOGRAPHY </p>
        <img src={Square} alt="square box" />
      </Box>

      <Box className="marquee-box">
        <p className="marquee-text">CREATIVE DIRECTOR </p>
        <img src={Square} alt="square box" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">EXECUTIVE PRODUCER </p>
        <img src={Square} alt="square box" />
      </Box>

      <Box className="marquee-box">
        <p className="marquee-text">VIDEOGRAPHER </p>
        <img src={Square} alt="square box" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">VIDEO EDITOR </p>
        <img src={Square} alt="square box" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">PROFESSIONAL STUDIO</p>
        <img src={Square} alt="square" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">PHOTOGRAPHY </p>
        <img src={Square} alt="square box" />
      </Box>

      <Box className="marquee-box">
        <p className="marquee-text">CREATIVE DIRECTOR </p>
        <img src={Square} alt="square box" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">EXECUTIVE PRODUCER </p>
        <img src={Square} alt="square box" />
      </Box>

      <Box className="marquee-box">
        <p className="marquee-text">VIDEOGRAPHER </p>
        <img src={Square} alt="square box" />
      </Box>
      <Box className="marquee-box">
        <p className="marquee-text">VIDEO EDITOR </p>
        <img src={Square} alt="square box" />
      </Box>
      
    </Marquee>
  );
};
